import React from 'react';
import "./Testimony.css"
import Zoom from "react-reveal";

const TestimonialCarousel = () => {

    const testimonials = [
        {
          content: 'Price are reasonable compare to other shops and services is good. On time delivery facility they will provided Iam highly satisfied with Purchase.',
          author: 'William',
        },
        {
          content: 'Good Granite slabs,they are not adding any liquids for shining,its purely Natural and direct polishing of  slabs. so its give us same shining life long.😍',
          author: 'Sujith Clipton Vas',
        },
        {
          content: 'Very good quality granite slabs. I am statisfied with the purchase. Rates are affordable and services is good',
          author: 'Divin',
        },
      ];


  const [currentIndex, setCurrentIndex] = React.useState(0);

  const handleNextClick = () => {
    setCurrentIndex((currentIndex + 1) % testimonials.length);
  };

  const handlePrevClick = () => {
    setCurrentIndex(currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1);
  };

  React.useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % testimonials.length);
    }, 5000);
    return () => clearInterval(timerId);
  }, [currentIndex, testimonials.length]);

  return (
    <div className='my-5'>
<Zoom bottom>
    <h3 className='text-center'>TESTIMONY</h3>
    <div className="container testimonial-carousel">
      <div className="testimonial-carousel__slide">
        <div className="testimonial-carousel__content">{testimonials[currentIndex].content}</div>
        <div className="testimonial-carousel__author">{testimonials[currentIndex].author}</div>
      </div>
      <div className="testimonial-carousel__buttons">
        <button className="testimonial-carousel__button" onClick={handlePrevClick}>
   prev
        </button>
        <button className="testimonial-carousel__button" onClick={handleNextClick}>
       next
        </button>
      </div>
    </div>
    </Zoom>
    </div>
  );
};

export default TestimonialCarousel;
